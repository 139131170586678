<template>
  <svg
    width="34"
    height="34"
    viewBox="0 0 34 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <circle
      cx="16.908"
      cy="20.8746"
      r="7.53056"
      fill="#D1D7DF"/>
    <path d="M16.4165 24.36L16.4494 23.5345C15.8461 23.5045 15.2494 23.3275 14.908 23.1121L15.1951 22.3176C15.5636 22.5238 16.0968 22.7156 16.6813 22.738C17.2888 22.7596 17.7124 22.4986 17.7247 22.0941C17.738 21.71 17.4081 21.4493 16.7115 21.2026C15.7108 20.8452 15.0697 20.4209 15.0995 19.624C15.1229 18.8846 15.7211 18.3434 16.6977 18.2121L16.7255 17.3746L17.5122 17.3974L17.4824 18.1943C18.0857 18.2242 18.4971 18.3676 18.7937 18.5153L18.5056 19.2894C18.2755 19.1881 17.8612 18.9836 17.2079 18.9638C16.5316 18.9448 16.2799 19.2442 16.271 19.5302C16.2609 19.8856 16.597 20.0889 17.4071 20.4006C18.4692 20.7925 18.9383 21.2682 18.9065 22.0243C18.878 22.7518 18.2931 23.3781 17.2331 23.4963L17.2074 24.3746L16.4165 24.36Z" fill="#193560"/>
    <path
      d="M1.33908 32.5405L32.5835 32.8038"
      stroke="#193560"
      stroke-linejoin="round"/>
    <path
      d="M4.73535 15.8235V32.8042"
      stroke="#193560"
      stroke-linejoin="round"/>
    <path
      d="M29.1865 26.0886L29.1865 32.8809"
      stroke="#193560"
      stroke-linejoin="round"/>
    <path
      d="M29.1865 23.2961L29.1865 24.6546"
      stroke="#193560"
      stroke-width="1.31523"
      stroke-linejoin="round"/>
    <path
      d="M29.1865 15.8235L29.1865 21.9365"
      stroke="#193560"
      stroke-linejoin="round"/>
    <path d="M25.0193 4.53747H28.2193V10.4803L25.0193 7.73747V4.53747Z" fill="#D1D7DF"/>
    <path
      d="M24.999 7.89296V4.88121H28.199V10.8812"
      stroke="#193560"
      stroke-linejoin="round"/>
    <path
      d="M1 14.1258L3.37729 17.1823L16.9618 5.65225L30.5464 17.1823L32.584 14.805L16.9618 0.880859L1 14.1258Z"
      stroke="#193560"
      stroke-linejoin="round"/>
    <path
      d="M22.6692 18.5808H25.223L22.3213 15.6773C21.3871 14.7431 20.2234 14.0712 18.9472 13.7294C17.671 13.3875 16.3273 13.3876 15.0512 13.7297C13.7751 14.0717 12.6115 14.7437 11.6774 15.6781C10.7434 16.6124 10.0717 17.7762 9.73005 19.0525M8.77588 25.9729V23.4192M8.77588 23.4192H11.3296M8.77588 23.4192L11.6767 26.3228C12.6109 27.257 13.7746 27.9288 15.0508 28.2707C16.3269 28.6126 17.6706 28.6125 18.9468 28.2704C20.2229 27.9283 21.3865 27.2563 22.3205 26.322C23.2546 25.3876 23.9262 24.2238 24.2679 22.9476M25.223 16.0271V18.579"
      stroke="#193560"
      stroke-linecap="round"
      stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: 'IconsVerticalsReverseMortgage'
}
</script>